import '../css/Common.css'
import '../css/Contact.css'

import { Footer } from "./Footer";
import { AiOutlinePhone } from "react-icons/ai";
import { BiMap } from "react-icons/bi"
import { BsEnvelope } from "react-icons/bs"

import {Header} from '../components/Header'

export default function Contact() {
    return (
        <div>
            <Header/>
            <div className="Page Contact-Page">
                
                <div className="Title">
                    <h1>Contact Us</h1>
                </div>

                {/* <div className="Sub-Title">
                    <p>Get in touch with us</p>
                </div> */}
                <div className="Contact-Body">
                    <div className="Contact-Section-1">
                        <a href='tel:916-357-6764'>
                            <div className="Contact-Icon">
                                <AiOutlinePhone size={'40%'} color='#e4e4e4'/>
                            </div>
                        </a>
                        <div className="Contact-Description">
                            <h3>Phone</h3>
                            <br />
                            <a href='tel:916-357-6764'>916-357-6764</a>
                        </div>
                    </div>

                    {/* <div className="Contact-Section">
                        <div className="Contact-Icon">
                            <BiMap size={'40%'} color='#FFFFFF90'/>
                        </div>
                        <div className="Contact-Description">
                            Location:
                            <br />
                            Your Address Here
                        </div>
                    </div> */}

                    <div className="Contact-Section-2">
                        <a href='mailto:LaurieH@Abbeyca.com'>
                            <div className="Contact-Icon">
                                <BsEnvelope size={'25%'} color='#e4e4e4'/>
                            </div>
                        </a>
                        <div className="Contact-Description">
                            <h3>Email</h3>
                            <br />
                            <a href='mailto:LaurieH@Abbeyca.com'>LaurieH@AbbeyCA.com</a>
                        </div>
                    </div>

                    <div className="Contact-Section-3">
                        <a  href={"https://www.google.com/maps/search/?api=1&query=1024+Iron+Point+Rd,+Folsom+CA,+95630"}>
                            <div className="Contact-Icon">
                                <BiMap size={'40%'} color='#e4e4e4'/>
                            </div>
                        </a>
                        <div className="Contact-Description">
                            <h3>Address</h3>
                            <br />
                            <a href={"https://www.google.com/maps/search/?api=1&query=1024+Iron+Point+Rd,+Folsom+CA,+95630"}>1024 Iron Point Rd, Folsom CA, 95630</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}