import { Footer } from "./Footer";
import { Header } from "./Header";
import '../css/Common.css'
// import logo from '../images/logo.png';
import stock from '../images/escrow.png'
import stock2 from '../images/team2.jpg'
import job4 from '../images/teamwork2.jpg';
import '../css/About.css'

export default function About() {
    return (
        <>
            <div className="Page">
                <Header/>
                <div className="About-Page">       

                    <div className="Intro-Section">
                        <div className="flex-row">
                            <h2>Abbey Escrow's Story</h2>
                        </div>
                        <div className="Intro-Synopsis">
                            Get to know Abbey Escrow — where service matters, integrity meets expertise, and every client is treated with care.
                        </div>
                    </div>

                    <div className="Info-Row">
                        <div className="Image-Section">
                            <img src={stock} width={'100%'} alt="Placeholder"/>
                        </div>
                        <div className="Text-Section">
                            <h2>Our Founding</h2>
                            <p>
                                Abbey Escrow was founded on the belief that each escrow transaction should be treated with the same level of care and diligence that 
                                our clients put into their most important financial decisions. Our team brings over 40 years of 
                                combined experience in the escrow industry. This wealth of knowledge forms the foundation of our service, ensuring that every client receives 
                                the attention and expertise they deserve. 
                                We understand that no two transactions are the same, and we pride ourselves on offering creative solutions to even the most 
                                complicated issues. Whether it's a straightforward closing or a complex, multi-faceted deal, we approach every challenge with flexibility 
                                and resourcefulness, no matter how big or small. Our mission is to provide peace of mind and ensure a smooth experience for each of our clients.
                                <br/><br/>
                                Join us in the journey of delivering escrow services that are rooted in trust, precision, and unwavering commitment.
                            </p>
                        </div>
                    </div>

                    <div className="Info-Row-alt">
                        <div className="Text-Section">
                            <h2>Abbey Escrow's Mission</h2>
                            <p>
                                At Abbey Escrow, our mission is to provide diligent, careful service that meets the unique needs of each client. 
                                We understand that every transaction is more than just a business deal; it’s a personal and financial milestone. 
                                With that in mind, we approach each escrow with the highest level of professionalism and care, ensuring that every detail is handled with precision. 
                                Our goal is to build lasting relationships with our clients, based on trust, transparency, and a shared commitment to success.
                            </p>
                        </div>
                        <div className="Image-Section">
                            <img src={job4} width={'100%'} alt="Placeholder" />
                        </div>
                    </div>

                    <div className="Info-Row">
                         <div className="Image-Section">
                            <img src={stock2} width={'100%'} alt="Placeholder" />
                        </div>
                        <div className="Text-Section">
                            <h2>Our Goals for the Future</h2>
                            <p>
                                As we look to the future, Abbey Escrow aims to continue growing as a trusted partner in the escrow and title industry, while staying true to the values that define us. We are committed to expanding our services without losing the personal touch that sets us apart. Whether it’s a first-time homebuyer or a seasoned investor, every client will always receive the care, dedication, and expertise that Abbey Escrow is known for.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}