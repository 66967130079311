import React from 'react';
import { Header } from './Header';
import '../css/Team.css';
import { Footer } from './Footer';
import laurie from '../images/laurieHeadshot.png';
import hailee from '../images/haileeHeadshot.png';

const teamGrid = {
  Laurie: {
    Image: laurie,
    Title: 'Laurie Hodgkins',
    Description: 'President and Chief Escrow Officer\n',
    Email: 'LaurieH@AbbeyCA.com',
    Phone: '916-357-6764'
  },
  Masonry: {
    Image: hailee,
    Title: 'Hailee Kosnikowski',
    Description: 'Escrow Assistant and Administrative Coordinator',
    Email: 'HaileeK@AbbeyCA.com',
    Phone: '916-357-6764'
  }
};

export default function Team() {
  return (
    <div className="Page">
      <Header/>
      <div className='Content'>
        <div className='Title'><h1>The Abbey Team</h1></div>
        <div className='grid'>
            {Object.values(teamGrid).map((topic, idx) => {
            return (
                <div key={idx} className='grid-item' onClick={console.log('clicked')}>
                  <div>
                      <img src={topic.Image} alt={topic.Title} />
                  </div>
                  <h2>{topic.Title}</h2>
                  <h3>{topic.Description}</h3>
                  <h4><a href={`mailto:${topic.Email}`}>{topic.Email}</a></h4>
                  <h4><a href={`tel:${topic.Phone}`}>{topic.Phone}</a></h4>
                </div>
            );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
}