import '../css/Footer.css'

export function Footer(){
    return(
            <footer>
                For Concerns or Inquiries, Please Use The Following Contact information. 
                <br/><t/>Email: LaurieH@AbbeyCA.com
                <br/><t/><a href='tel:916-357-6764' style={{color: '#FFFFFF'}}>Phone: (916) 357-6764</a>
            </footer>
        );
    }